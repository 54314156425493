
import { Options, Vue } from 'vue-class-component';
import { QrReceiptClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class Receipt extends Vue {

    list: OM.QRReceiptVM[] = [];

    created() {
        this.init();
    }

    init() {
        QrReceiptClient.getAll()
        .then(x => {
            this.list = x;
        })
    }

    open(imageUrl){
        window.open(imageUrl, "_blank")
    }

}
