import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "boxContainer" }
const _hoisted_3 = {
  filter: "claimedOn",
  sort: "claimedOn"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  colspan: "10",
  class: "text-center pt-5"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "backoffice_Receipt",
        text: "Receipt",
        class: "text-uppercase fw_700 mb-0"
      })
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_op_table, {
        class: "table table-striped",
        items: _ctx.list
      }, {
        default: _withCtx((opData) => [
          _createVNode("thead", null, [
            _createVNode("tr", null, [
              _createVNode("td", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "backoffice_Foto",
                  text: "Foto"
                })
              ]),
              _createVNode("td", _hoisted_3, [
                _createVNode(_component_localized_text, {
                  localizedKey: "backoffice_Claimed on",
                  text: "Claimed on"
                })
              ]),
              _createVNode("td", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "backoffice_ClaimedBy",
                  text: "Claimed By"
                })
              ])
            ])
          ]),
          _createVNode("tbody", null, [
            (opData.data.length == 0)
              ? (_openBlock(), _createBlock("tr", _hoisted_4, [
                  _createVNode("td", _hoisted_5, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "backoffice_NessunElementoTrovato",
                      text: "Nessun elemento trovato"
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(opData.data, (item, i) => {
              return (_openBlock(), _createBlock("tr", { key: i }, [
                _createVNode("td", null, [
                  _createVNode("img", {
                    style: {"height":"100px","cursor":"pointer"},
                    src: item.imageUrl,
                    alt: "",
                    onClick: ($event: any) => (_ctx.open(item.imageUrl))
                  }, null, 8, ["src", "onClick"])
                ]),
                _createVNode("td", null, [
                  (item.claimedOn)
                    ? (_openBlock(), _createBlock("span", _hoisted_6, _toDisplayString(_ctx.$filters.date(item.claimedOn)), 1))
                    : (_openBlock(), _createBlock("span", _hoisted_7, "-"))
                ]),
                _createVNode("td", null, [
                  (item.user)
                    ? (_openBlock(), _createBlock("span", _hoisted_8, _toDisplayString(item.user.text), 1))
                    : (_openBlock(), _createBlock("span", _hoisted_9, "-"))
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ], 64))
}